import React from "react";
import "./testimonials.css";
import AVTR1 from "../../assets/avatar1.jpg";
import AVTR2 from "../../assets/avatar2.jpg";
import AVTR3 from "../../assets/avatar3.jpg";
import AVTR4 from "../../assets/avatar4.jpg";

// core version + navigation, pagination modules:
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/pagination";

const data = [
  {
    avatar: AVTR1,
    name: "Mohsin Agha",
    review:
      " I was impressed by the professionalism and dedication demonstrated by Tahir Turk, the web developer behind the project. Their commitment to understanding my business needs and translating them into a functional, visually appealing website was evident throughout the entire process.",
  },
  {
    avatar: AVTR2,
    name: "Kashif Bhatti",
    review:
      "Working with Tahir has been a true pleasure, not only for the exceptional quality of their work but also for their unwavering commitment to delivering value and excellence. They have gone above and beyond to ensure that our website reflects the essence of our brand and provides a seamless experience for our customers.",
  },
  {
    avatar: AVTR3,
    name: "Makhdoom Fraz",
    review:
      "I am thrilled with the outcome of our collaboration and the tangible results it has delivered for our business.The e-commerce website developed by Tahir has become the cornerstone of our online presence, conversions and revenue growth. It has enabled us to reach new audiences,expand our product offerings.",
  },
  {
    avatar: AVTR4,
    name: "Muhammad Nadeem",
    review:
      "The design of our building materials website stands as a testament to creativity and functionality. Tahir Turk ingeniously crafted an interface that not only showcases our diverse range of products but also prioritizes user experience and ease of navigation. The intuitive layout, detailed product descriptions has elevated our online presence.",
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review From Clients</h5>
      <h2>Testimonials</h2>

      <Swiper
        className="container testimonials__container"
        pagination={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {data.map(({ avatar, name, review }, index) => {
          return (
            <div className="Swiper-wrapper">
              <SwiperSlide>
                <div className="testimonial" key={index}>
                  <div className="client__avatar">
                    <img src={avatar} alt="Avatar One" />
                  </div>
                  <h5 className="client__name">{name}</h5>
                  <small className="client__review">{review}</small>
                </div>
              </SwiperSlide>
            </div>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonials;
